
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Contact = () => {
  return (
    <>
      <div className="container home-page">
      See me on Linked in: 
        <a 
                target="_blank" 
                rel="noreferrer" 
                href="https://www.linkedin.com/in/mattchandlerus/">
                    <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
                </a><br></br>
                Also at my Portfolio site: <a target='_blank' href='http://mattchandler.us'>mattchandler.us</a>
      </div>
    </>
  )
}

export default Contact
