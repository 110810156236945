import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Services from './components/Services'
import Portfolio from './components/Portfolio';
import Learn from './components/Learn';
import Navbar from './components/Navbar'
import Layout from './components/Layout';



function App() {
  return (

      <div>
        <Navbar />
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/learn" element={<Learn />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Layout>
      </div>
      
       
  )
}

export default App;
