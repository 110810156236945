
import './index.scss'
import LogoS from "../../assets/images/hogcloud-logo1.png";

const Portfolio = () => {
  return (
    <>
      <div className="container hogcloud-site1">
      <img src={LogoS} alt="logo" />
      <p></p>
  
      </div>

    </>
  )
}

export default Portfolio
