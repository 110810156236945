import LogoS from "../../assets/images/hogcloud-logo1.png";
const Learn = () => {

    return ( <>
    
    <div className=""><img src={LogoS} alt="logo" />
        </div>
        <h1>Under construction</h1>
        <h2>Launch Date:  December 1, 2024</h2>
    </>);
}

export default Learn