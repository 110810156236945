import "./index.scss"
const Layout = ({children}) =>
{
    return <>
        <main>{children}</main>
        <footer>(c){new Date().getFullYear()} Hogcloud, Inc. </footer>
    </>;

}

export default Layout;