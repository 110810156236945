
import './index.scss'

import ServiceItem from '../ServiceItem'


const Services = () => {
  return (
    <>
    <section>
        <div className="hc-layout-1">

   
       
        </div>
        <ServiceItem />

      </section>
    </>
  )
}

export default Services
