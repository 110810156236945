
import './index.scss'
import LogoS from "../../assets/images/hogcloud-logo1.png";

const Home = () => {
  return (
    <>
      <div className="container">
        <div className=""><img src={LogoS} alt="logo" />
        </div>
        <h1>Under construction</h1>
        <h2>Launch Date:  December 1, 2024</h2>
      </div>
    </>
  )
}

export default Home
