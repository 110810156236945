import "./index.scss"

import logoPath from '../../assets/images/aws-logo-1.png'

const ServiceItem = () =>{

    return (
        <>
            <div class="hc-service-item-title"></div>
            <image src={logoPath} />
            <div class="hc-service-item-image"><img src={logoPath} alt="AWS logo"/></div>
            <div class="hc-service-item-body">Customized solutions offered by Certified AWS Solutions Architect</div>
        </>
    );

};

export default ServiceItem;