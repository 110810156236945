
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const About = () => {
  return (
    <>
      <div className="container  home-page">
        <div>
          <h4>
          Hogcloud is a very small company founded and owned by Matt Chandler in Fayetteville, AR.</h4><br></br>
          
        </div>
      </div>
    </>
  )
}

export default About
